<div class="page-container home-page-container">
  <ng-container *ngIf="loading; else normalComponents">
    <section class="page-section navigation-section">
      <div class="container navigation-container">
        <div *ngIf="!isMobileScreen" class="home-page-header-navigation--skeleton"></div>
        <div class="home-page-header-search--skeleton"></div>
        <div *ngIf="isMobileScreen" class="rewards-balance-badge-skeleton"></div>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <skeleton-promo-banners></skeleton-promo-banners>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <skeleton-home-shops-section></skeleton-home-shops-section>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <skeleton-home-shops-section></skeleton-home-shops-section>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <skeleton-home-shops-section></skeleton-home-shops-section>
      </div>
    </section>
    <section class="page-section">
      <div class="container home-page-discover-button-container">
        <div class="home-page-discover-button--skeleton"></div>
      </div>
    </section>
  </ng-container>
  <ng-template #normalComponents>
    <unsupported-area *ngIf="isAreaSupported === false"></unsupported-area>
    <ng-container *ngIf="isAreaSupported">
      <section class="page-section navigation-section">
        <div class="container navigation-container">
          <box-navigation [options]="boxNavigationOptions" *ngIf="!isMobileScreen"></box-navigation>
          <home-search *ngIf="!isSeoLocationPage"></home-search>
          <rewards-balance-badge *ngIf="isMobileScreen"></rewards-balance-badge>
        </div>
      </section>
      <ng-container *ngFor="let homeSection of homeSections">
        <section class="page-section home-page-section">
          <div class="container" [ngSwitch]="homeSection.type">
            <promo-banners *ngSwitchCase="'banners'" [homeSection]="homeSection"></promo-banners>
            <contests-section *ngSwitchCase="'contests'" [homeSection]="homeSection"></contests-section>
            <home-cuisines *ngSwitchCase="'foodCuisines'" [homeSection]="homeSection"></home-cuisines>
            <home-cuisines *ngSwitchCase=" 'groceriesCuisines'" [homeSection]="homeSection"></home-cuisines>
            <single-banner
              *ngSwitchCase="'singleBanner'"
              [config]="homeSection.singleBannerConfig"
              (removeSection)="onRemoveSection(homeSection)"
              (ctaButtonClick)="onCtaButtonClicked(homeSection.singleBannerConfig)"
              visibilityObserver
              [triggerOnce]="true"
              [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
              (enteredViewport)="onSingleBannerEnteredViewport(homeSection.singleBannerConfig)"
            ></single-banner>
            <home-coupons-section *ngSwitchCase="'coupons'" [homeSection]="homeSection"></home-coupons-section>
            <home-discover-filters
              *ngSwitchCase="'discoverFilters'"
              [homeSection]="homeSection"
            ></home-discover-filters>
            <order-status-previews
              *ngSwitchCase="'pendingOrders'"
              [orders]="homeSection.orders"
            ></order-status-previews>
            <home-brands *ngSwitchCase="'popularBrands'" [homeSection]="homeSection"></home-brands>
            <ng-container *ngSwitchDefault>
              <home-shops-section [homeSection]="homeSection"></home-shops-section>
            </ng-container>
          </div>
        </section>
      </ng-container>
      <section class="page-section">
        <div class="container home-page-discover-button-container">
          <button
            box-button
            class="home-page-discover-button ods-typography-body-m-regular"
            size="small"
            theme="insignificant"
            (click)="onNavigateToDiscover()"
            visibilityObserver
            [triggerOnce]="true"
            [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
            (enteredViewport)="onSeeAllButtonEnteredViewport()"
          >
            {{'see_all_shops' | translate}}
          </button>
        </div>
      </section>
      <ng-container *ngIf="isSeoLocationPage">
        <section *ngIf="location?.description" class="section location-page-section">
          <div class="container">
            <h2 class="location-page-section-title">
              {{'everything_to_know_about_area' | translate}} {{ location?.name }}
            </h2>
            <span class="location-page-description" [innerHTML]="location.description"></span>
          </div>
        </section>
        <section *ngIf="cuisinesAnchors?.length > 0" class="section location-page-section">
          <div class="container">
            <delivery-anchors
              [title]="('find_suitable_cuisines_for_area' | translate) + ' ' + location?.name"
              [anchors]="cuisinesAnchors"
            ></delivery-anchors>
          </div>
        </section>
        <section *ngIf="chainShopsAnchors?.length > 0" class="section location-page-section">
          <div class="container">
            <delivery-anchors
              [title]="('shops_in_area' | translate) + ' ' + location?.name"
              [anchors]="chainShopsAnchors"
            ></delivery-anchors>
          </div>
        </section>
        <section *ngIf="newShopsAnchors?.length > 0" class="section location-page-section">
          <div class="container">
            <delivery-anchors
              [title]="('new_shops_in_area' | translate) + ' ' + location?.name"
              [anchors]="newShopsAnchors"
            ></delivery-anchors>
          </div>
        </section>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
