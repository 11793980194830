import { Injectable } from '@angular/core';
import {
  CoreService,
  SEOService,
  ConfigurationService,
  UserService,
  HomeSectionsService,
  OrdersService,
  DiscoverFiltersService,
  TimeslotsService,
  PromoCampaignsService,
  DialogService,
  ShopsService
} from '@box-core/services';
import { PromoCampaign, Shop, Contest, Coupon } from '@box-types';
import { Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import {
  decorateAndFilterBannerSections,
  decorateAndFilterContestSections,
  decorateAndFilterShopSections,
  decorateAndFilterCuisineSections,
  decorateAndFilterFiltersSections,
  HomeSection,
  decorateAndFilterSingleBannersSections,
  decoratePopularBrandsSection,
  decoratePendingOrdersSection,
  CollectionCampaign,
  updateCollectionCampaignSession,
  getSortedPriorityCollectionCampaigns,
  canCollectionCampaignBeSeen,
  getLastWeekOrders,
  getOrdersTotalDiscountPrice,
  currencyFormat,
  CurrencyFormatOptions,
  storageGet,
  decorateAndFilterCouponsSections,
  isFirstLoginThisMonth
} from '@box/utils';
import {
  CollectionCampaignDialogResponseData,
  CollectionCampaignsDialogData
} from '@box-core/components/collection-campaigns-dialog/collection-campaign-dialog.types';
import { CollectionCampaignsDialogComponent, GBPromoDialogComponent } from '@box-core/components';
import { last, tap } from 'rxjs/operators';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { currencyCode } from '@box-core/services/currency.service';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { getLastMonthOrders } from '@box/utils/src/order/order-history.utils';

dayjs.extend(weekday);

@Injectable()
export class HomePageService {
  constructor(
    private coreService: CoreService,
    private seoService: SEOService,
    private configService: ConfigurationService,
    private userService: UserService,
    private homeSectionsService: HomeSectionsService,
    private ordersService: OrdersService,
    private discoverFiltersService: DiscoverFiltersService,
    private timeslotsService: TimeslotsService,
    private promoCampaignsService: PromoCampaignsService,
    private router: Router,
    private dialogService: DialogService,
    private shopsService: ShopsService,
    private globalStateService: GlobalStateService
  ) {}

  public setMetaTags(): void {
    const title = 'my_box';
    const url = '/';
    const options = { title, url };
    this.seoService.setTags(options);
  }

  public decorateFilterAndSortSections(shops: Shop[], contests: Contest[], coupons: Coupon[]): HomeSection[] {
    const sections = this.homeSectionsService.getHomeSections() as Readonly<HomeSection[]>;
    const promoBanners = this.globalStateService.getPromoBanners();
    const discoverFilters = this.discoverFiltersService.getDiscoverFilters();
    const orders = this.globalStateService.getOrderHistory();
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    const timeslot = this.timeslotsService.getTimeslot();

    const bannerSections: HomeSection[] = [];
    const contestsSections: HomeSection[] = [];
    const discoverFilterSections: HomeSection[] = [];
    const shopSections: HomeSection[] = [];
    const foodCuisineSections: HomeSection[] = [];
    const groceriesCuisineSections: HomeSection[] = [];
    const singleBannersSections: HomeSection[] = [];
    const popularBrandsSections: HomeSection[] = [];
    const couponsSections: HomeSection[] = [];
    let pendingOrdersSection: HomeSection;
    sections.forEach((section) => {
      switch (section.type) {
        case 'banners':
          bannerSections.push(section);
          break;
        case 'contests':
          contestsSections.push(section);
          break;
        case 'discoverFilters':
          discoverFilterSections.push(section);
          break;
        case 'foodCuisines':
          foodCuisineSections.push(section);
          break;
        case 'groceriesCuisines':
          groceriesCuisineSections.push(section);
          break;
        case 'singleBanner':
          singleBannersSections.push(section);
          break;
        case 'pendingOrders':
          pendingOrdersSection = section;
          break;
        case 'popularBrands':
          popularBrandsSections.push(section);
          break;
        case 'coupons':
          couponsSections.push(section);
          break;
        default:
          shopSections.push(section);
      }
    });

    const decoratedSingleBannerSections = decorateAndFilterSingleBannersSections(
      singleBannersSections,
      this.configService.getConfiguration(),
      this.userService.isGuest
    );

    const decoratedBannerSections = decorateAndFilterBannerSections(bannerSections, promoBanners);
    const decoratedContestsSection = decorateAndFilterContestSections(contestsSections, contests, shops);
    const decoratedFiltersSection = decorateAndFilterFiltersSections(discoverFilterSections, shops, discoverFilters);
    const decoratedFoodCuisineSections = decorateAndFilterCuisineSections(foodCuisineSections, cuisines, shops, 'food');
    const decoratedGroceriesCuisineSections = decorateAndFilterCuisineSections(
      groceriesCuisineSections,
      cuisines,
      shops,
      'groceries'
    );

    const decoratedPopularBrandsSections = decoratePopularBrandsSection(
      popularBrandsSections,
      chains,
      shops,
      this.globalStateService.getAddress()
    );

    const decoratedShopSections = decorateAndFilterShopSections(
      shopSections,
      shops,
      cuisines,
      orders,
      chains,
      timeslot
    );

    const decoratedCouponsSections = decorateAndFilterCouponsSections(couponsSections, coupons);

    const decoratedSections = [
      ...decoratedBannerSections,
      ...decoratedFiltersSection,
      ...decoratedContestsSection,
      ...decoratedFoodCuisineSections,
      ...decoratedGroceriesCuisineSections,
      ...decoratedPopularBrandsSections,
      ...decoratedShopSections,
      ...decoratedSingleBannerSections,
      ...decoratedCouponsSections
    ];

    const decoratedPendingOrdersSection = decoratePendingOrdersSection(pendingOrdersSection, orders);
    if (decoratedPendingOrdersSection) decoratedSections.push(decoratedPendingOrdersSection);
    return decoratedSections.sort((a, b) => a.position - b.position);
  }

  public showCollectionCampaignsDialog$(): Observable<null> {
    const dialogData = this.getCollectionCampaignsDialogData();
    if (!dialogData?.collectionCampaigns) return of(null);
    const dialogRef = this.dialogService.openDialog(CollectionCampaignsDialogComponent, {
      panelClass: 'box-dialog-fit-content',
      data: dialogData
    });
    dialogRef.afterOpened().subscribe(() => updateCollectionCampaignSession(dialogData.collectionCampaigns));
    dialogRef.afterClosed().subscribe((data: CollectionCampaignDialogResponseData) => {
      this.afterCollectionCampaignDialogClosed(data);
    });
    return dialogRef.afterClosed().pipe(switchMap(() => of(null)));
  }

  private getCollectionCampaignsDialogData(): CollectionCampaignsDialogData {
    const collectionCampaigns = this.promoCampaignsService.getCampaignModalPromoCampaigns();

    const sortedCollectionCampaigns: PromoCampaign[] = getSortedPriorityCollectionCampaigns(collectionCampaigns);
    if (!sortedCollectionCampaigns?.length) return;

    const campaignsToBeSeen = sortedCollectionCampaigns.filter((campaign) => canCollectionCampaignBeSeen(campaign));
    if (!campaignsToBeSeen?.length) return;
    return this.generateCollectionCampaignDialogData(campaignsToBeSeen);
  }

  private afterCollectionCampaignDialogClosed(data: CollectionCampaignDialogResponseData): void {
    if (!data || !data.navigateToDiscover) return;
    // This will be changes after Discover Page redesign
    if (!data.collectionCampaign) return void this.router.navigate(['/discover']);
    const filter = data.collectionCampaign.filter;
    const quickFilters = (filter.quickFilters ?? []).join(',');
    const queryParams = { vertical: filter.businessVertical, ...(quickFilters?.length && { filters: quickFilters }) };
    this.router.navigate(['/discover'], { queryParams }); // eslint-disable-line @typescript-eslint/no-floating-promises
  }

  private generateCollectionCampaignDialogData(sortedCampaigns: PromoCampaign[]): CollectionCampaignsDialogData {
    const shops = this.globalStateService.getShops();
    const openShops = shops.filter((shop) => shop.operatingState === 'OPEN');
    const collectionCampaigns: CollectionCampaign[] = sortedCampaigns
      .map((campaign) => this.promoCampaignsService.promoCampaignToCollectionCampaign(campaign, openShops))
      .filter((collectionCampaign) => collectionCampaign.shops.length > 0);
    if (!collectionCampaigns?.length) return;
    return { collectionCampaigns };
  }

  public checkGBPromoDialog$(): Observable<null> {
    const userSegment = this.userService.getUserMainSegment();
    if (!userSegment) return of(null);
    const hasSeenGBPromoBanner = window.localStorage.getItem('Box:gbPromoSession');
    if (hasSeenGBPromoBanner) return of(null);
    return this.openGBPromoDialog$(userSegment);
  }

  private openGBPromoDialog$(segment: string): Observable<null> {
    return this.dialogService
      .openDialog(GBPromoDialogComponent, {
        panelClass: 'box-dialog',
        closeOnNavigation: true,
        data: { segment }
      })
      .afterClosed()
      .pipe(
        tap(() => window.localStorage.setItem('Box:gbPromoSession', 'true')),
        switchMap(() => of(null))
      );
  }

  public shouldShowRewardsPointsNotification(): boolean {
    const isFirstLoginThisMonthVar = isFirstLoginThisMonth();
    if (isFirstLoginThisMonthVar) return true;
    const seenTimestamp: number = storageGet('Box:rewardsPointsNotification', window.localStorage);
    if (!seenTimestamp) return true;
    // Find most recent Monday (Monday = 1)
    const currentWeekMonday = dayjs().weekday(0).hour(0).minute(0).second(0).unix();
    if (seenTimestamp <= currentWeekMonday) return true;
    return false;
  }

  public getRewardsPointsNotificationData(isMonthlyRewards: boolean): string {
    const orders = this.globalStateService.getOrderHistory();
    if (!orders?.length) return null;
    const lastOrders = isMonthlyRewards ? getLastMonthOrders(orders) : getLastWeekOrders(orders);
    if (!lastOrders?.length) return null;
    const ordersTotalDiscountPrice = getOrdersTotalDiscountPrice(lastOrders);
    if (ordersTotalDiscountPrice === 0) return null;
    const currencyFormatOptions = {
      minimumFractionDigits: 2,
      symbolSpace: true,
      currencyCode: currencyCode
    } as CurrencyFormatOptions;
    return currencyFormat(ordersTotalDiscountPrice, currencyFormatOptions);
  }
}
