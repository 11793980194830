import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Shop,
  Address,
  Coupon,
  PromoBanner,
  Order,
  PromoCampaign,
  User,
  MarketCardDetails,
  Grouping,
  Location
} from '@box-types';
import { storageRemove } from '@box/utils';

@Injectable({ providedIn: 'root' })
export class GlobalStateService {
  public shopsSource = new BehaviorSubject<Shop[]>([]);
  public shops$ = this.shopsSource.asObservable();

  public setShops(shops: Shop[]): void {
    this.shopsSource.next(shops);
  }

  public getShops(): Shop[] {
    return this.shopsSource.getValue();
  }

  public readonly ADDRESS_STORAGE_KEY = 'Box:address';
  public readonly address = new BehaviorSubject<Address>(null);
  private readonly addresses = new BehaviorSubject<Address[]>([]);
  public readonly address$ = this.address.asObservable();
  public readonly addresses$ = this.addresses.asObservable();

  public getAddress(): Address {
    return this.address.getValue();
  }

  public getAddresses(): Address[] {
    return this.addresses.getValue();
  }

  public setAddresses(addresses: Address[]): void {
    this.addresses.next(addresses);
  }

  public clearAddress(): void {
    storageRemove(this.ADDRESS_STORAGE_KEY, window.localStorage);
    this.address.next(null);
  }

  public clearAddresses(): void {
    this.addresses.next([]);
  }

  private readonly availableCouponsSource = new BehaviorSubject<Coupon[]>([]);
  public readonly availableCoupons$ = this.availableCouponsSource.asObservable();

  private readonly unavailableCouponsSource = new BehaviorSubject<Coupon[]>([]);
  public readonly unavailableCoupons$ = this.unavailableCouponsSource.asObservable();

  public readonly decoratedCouponsSource = new BehaviorSubject<Coupon[]>([]);
  public readonly decoratedCoupons$ = this.decoratedCouponsSource.asObservable();

  public getAvailableCoupons(): Coupon[] {
    return this.availableCouponsSource.getValue();
  }

  public setAvailableCoupons(coupons: Coupon[]): void {
    this.availableCouponsSource.next(coupons);
  }

  public getDecoratedCoupons(): Coupon[] {
    return this.decoratedCouponsSource.getValue();
  }

  public setDecoratedCoupons(coupons: Coupon[]): void {
    this.decoratedCouponsSource.next(coupons);
  }

  public getUnavailableCoupons(): Coupon[] {
    return this.unavailableCouponsSource.getValue();
  }

  public setUnavailableCoupons(coupons: Coupon[]): void {
    this.unavailableCouponsSource.next(coupons);
  }

  private readonly promoBannersSource: BehaviorSubject<PromoBanner[]> = new BehaviorSubject<PromoBanner[]>([]);
  public readonly promoBanners$: Observable<PromoBanner[]> = this.promoBannersSource.asObservable();

  public getPromoBanners(): PromoBanner[] {
    return this.promoBannersSource.getValue();
  }

  public setPromoBanners(promoBanners: PromoBanner[]): void {
    this.promoBannersSource.next(promoBanners);
  }

  private readonly orderSource: BehaviorSubject<Order> = new BehaviorSubject<Order>(undefined);
  public readonly order$: Observable<Order> = this.orderSource.asObservable();

  private readonly orderHistorySource = new BehaviorSubject<Order[]>([]);
  public readonly orderHistory$ = this.orderHistorySource.asObservable();

  public setOrder(order: Order): void {
    this.orderSource.next(order);
  }

  public getOrder(): Order {
    return this.orderSource.getValue();
  }

  public clearOrder(): void {
    return this.orderSource.next(undefined);
  }

  public setOrderHistory(orders: Order[]): void {
    this.orderHistorySource.next(orders);
  }

  public getOrderHistory(): Order[] {
    return this.orderHistorySource.getValue();
  }

  private readonly promoCampaignsSource = new BehaviorSubject<PromoCampaign[]>([]);
  public readonly promoCampaigns$ = this.promoCampaignsSource.asObservable();

  public getPromoCampaigns(): PromoCampaign[] {
    return this.promoCampaignsSource.getValue();
  }

  public setPromoCampaigns(promoCampaigns: PromoCampaign[]): void {
    this.promoCampaignsSource.next(promoCampaigns);
  }

  private readonly userSource: BehaviorSubject<User> = new BehaviorSubject<User>({});
  public readonly user$ = this.userSource.asObservable();

  private readonly tempLoyaltyCardsSource = new BehaviorSubject<MarketCardDetails[]>([]);
  public readonly tempLoyaltyCards$ = this.tempLoyaltyCardsSource.asObservable();

  public setUser(user: User): void {
    this.userSource.next(user);
  }

  public getUser(): User {
    return this.userSource.getValue();
  }

  public getUser$(): Observable<User> {
    return this.user$;
  }

  public setTempLoyaltyCards(loyaltyCards: MarketCardDetails[]): void {
    this.tempLoyaltyCardsSource.next(loyaltyCards);
  }

  public getTempLoyaltyCards(): MarketCardDetails[] {
    return this.tempLoyaltyCardsSource.getValue();
  }

  public getSavedLoyaltyCards(): MarketCardDetails[] {
    return this.userSource.getValue()?.loyaltyCards ?? [];
  }

  private readonly eligibleGroupingsSource: BehaviorSubject<Grouping[]> = new BehaviorSubject<Grouping[]>([]);
  public readonly eligibleGroupings$ = this.eligibleGroupingsSource.asObservable();

  public getEligibleGroupings(): Grouping[] {
    return this.eligibleGroupingsSource.getValue();
  }

  public setEligibleGroupings(groupings: Grouping[]): void {
    this.eligibleGroupingsSource.next(groupings);
  }

  private readonly locationSource = new BehaviorSubject<Location>(null);
  public readonly location$ = this.locationSource.asObservable();

  public getLocation(): Location {
    return this.locationSource.getValue();
  }

  public setLocation(location: Location): void {
    this.locationSource.next(location);
  }

  public clearLocation(): void {
    this.locationSource.next(null);
  }
}
